@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Abril+Fatface|Lato|Nunito+Sans:100,200,400,600);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multiselect.dropdown-container {
    background-color: red !important;
}

.h1, .h2 {
  font-family:Montserrat,Abril Fatface,Times New Roman,Times,serif;
}
